<template>
  <div>
    <v-container fluid>
      <!-- header title  -->
      <v-row>
        <v-col cols="6">
          <div class="font-weight-bold">Tiêu đề</div>
        </v-col>
        <v-col cols="6">
          <v-icon>mdi-edit</v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="exam.title"
            name="name"
            label="Nhập tiêu đề"
            id="id"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- main select box  -->
    
     <div
    class="mx-auto"
    style="max-width:800px"
  >  <v-row
        ><v-col
          ><div class="headline">
            Chọn <span class="font-weight-bold">Bài thi</span>
          </div></v-col
        ></v-row
      >
      <v-row  dense>
        <v-col v-for="card in cards" :key="card.title" :cols="card.flex">
          <v-card
            active-class="border-style"
            @click="selectExam(card)"
           :class="{hightlight:card.isChoose}"
          >
            <v-img
              :src="`./images/` + card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title v-text="card.title"></v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
     </div>
      <v-row
        ><v-col class="text-center pa-10"
          ><v-btn @click="submitExam" color="primary">Tiếp tục</v-btn></v-col
        ></v-row
      >
    </v-container>
  </div>
</template>

<script>
import { EXAM } from "@/common/constants";
import { SAVE_EXAM } from "@/store/exams.module";
export default {
  data() {
    return {
      selected: {},
      exam: {
        title: "",
        type: 0,
        lesson_id: 0,
      },
      outlined: true,
      image: "",
      cards: [
        {
          title: "Listening",
          type: EXAM.LISTENING,
          src: "exam-listening.jpg",
          flex: 6,
          isChoose:false,
        },
        {
          title: "Reading",
          type: EXAM.READING,
          src: "exam-reading.jpg",
          flex: 6,
          isChoose:false,
        },
      ],
    };
  },
  mounted() {
    this.exam.lesson_slug = this.$route.query.lesson_slug;
  },
  methods: {
    clearBorder(){
      this.cards.forEach(item=>{
        item.isChoose=false
      })
    },
    selectExam(exam) {
      this.clearBorder();
      exam.isChoose=true;
      this.exam.type = exam.type;
    },
    submitExam() {
      this.$store
        .dispatch(SAVE_EXAM, {
          exam: this.exam,
        })
        .then(() => {
          this.$router.push({
            path: "/lesson/exam",
            query: { lesson_slug: this.$route.query.lesson_slug },
          });
        });
    },
  },
};
</script> 
<style >
.hightlight{
  border: 2px solid lawngreen !important;
}
</style>